import { FunctionComponent, useEffect, useState } from "react";
import axios from "axios";
import { Tile } from "../components/tile/tile";
import styles from "./thirdPartyLibs.module.scss";
import { Header } from "../components/header/Header";
import { ILicense } from "src/interfaces/Licenses.interface";
import { FullScreenLoader } from "../components/fullScreenLoader/FullScreenLoader";
import { Errors } from "../components/errors/Errors";
import { IPlugin, Platforms } from "../interfaces/Plugin.interface";
import { browserName, osName } from 'react-device-detect';

const errorMessage = "Nothing found, please contact an admin"

interface PluginListProps {
  magazine: string;
  token: string;
}

export const PluginList: FunctionComponent<PluginListProps> = (props: PluginListProps) => {
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<IPlugin[]>([]);

  const [licences, setLicences] = useState<ILicense[]>([])
  const [currentLicence, setCurrentLicence] = useState<string>()
  const [groupedItems, setGroupedItems] = useState<{ [key: string]: IPlugin[] }>({});

  useEffect(() => {
    fetchItems();
  }, [props.magazine]);
  useEffect(() => {
    groupItem();
  }, [items]);

  const getPlatform = (): string => {
    const os = osName.toLowerCase();
    if (os === 'ios' && browserName === 'none') return Platforms[0];
    if (os === 'android' && browserName === 'none') return Platforms[1];
    return Platforms[2];
 }
  const fetchItems = async () => {
    const url = `/api/ThirdPartyPlugin/client/${props.magazine}?platform=${getPlatform()}`;
    const headers = {
      Accept: "application/json",
      Authorization: props.token || "",
    };
    try {
      const { data } = await axios.get(url, { headers: headers });
      setItems(data);
      setLoading(false)
    } catch (error) {
      console.error(error);
      setError(true)
    }
  };

  const fetchfiles = async (url: string, itemId: number) => {
    const headers = {
      Accept: "application/json",
    };
    try {
      const { data } = await axios.post(`/api/ThirdPartyPlugin/licenses`, { url }, { headers: headers });
      setCurrentLicence(data);

      const newLisence: ILicense = {
        id: itemId,
        content: data,
      }

      setLicences((prevItems) => [newLisence, ...prevItems]);
      setLoading(false)

    } catch (error) {
      console.error(error);
    }
  };


  const createPopup = async (plugin: IPlugin) => {
    setCurrentLicence(undefined)


    let notInList = true
    notInList = licences.some(item => item.id === plugin.id);
    if (!notInList) {
      fetchfiles(plugin.url, plugin.id)
      return
    }
    setCurrentLicence(licences.find(item => item.id === plugin.id)?.content);
  }
  const groupItem = () => {
    const grouped: { [key: string]: IPlugin[] } = {};
    Platforms.forEach(platform => {
      grouped[platform] = [];
    });

    items.forEach(item => {
      if (Platforms.includes(item.platform)) {
        grouped[item.platform].push(item);
      }
    });

    setGroupedItems(grouped);
  };

  const generateSection = (platform: string) => {
    return (
      <div key={platform}>
        <div className={styles.header}>
          {getHeaderText(platform)}
        </div>
        {groupedItems[platform].map((item) => (
          <Tile
            key={item.id}
            plugin={item}
            setPopup={createPopup}
            content={currentLicence ?? errorMessage}
          />
        ))}
      </div>
    );
  }
  return (
    <div>
      {error ? (
        <Errors msg={errorMessage}></Errors>
      ) : loading ? (
        <FullScreenLoader></FullScreenLoader>
      ) : (
        <>
          <Header />
          <div className={styles.tileContainer}>
            {Platforms.map(platform => {
              if (groupedItems[platform]?.length > 0) {
                return generateSection(platform)
              }
              return null
            })}
          </div>

        </>
      )}
    </div>
  );
};

//-----Helper Functions--------------------------------------------------------------------------------

const getHeaderText = (platform: string) => {
  if (platform === 'plugin') return 'Plugins';
  if (platform === 'webapp') return 'Platform';
  return platform;
};